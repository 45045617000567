<template>
  <message v-if="error">
    <template slot="title">{{error.title || 'Unknown error'}}</template>
    <template slot="content">{{error.message || ''}}</template>
  </message>
  <div v-else class="post__wrapper">
    <helmet>
      <title>{{$t('title.postEdit')}}</title>
    </helmet>
    <div class="post__content-post">
      <div class="post__header-row">
        <h5 class="post-editor-header">
          {{$t('post.headers.appearance')}}
          <transition name="fade-200">
            <el-tooltip
              v-if="!textCorrect"
              effect="dark"
              :content="textErrorTooltipText"
              placement="bottom">
              <i class="material-icons sign sign_error">error_outline</i>
            </el-tooltip>
          </transition>
        </h5>
      </div>
      <post-media-position-switcher :position="state.mediaPosition">
        <post-quill
          class="post__quill-editor"
          v-model="state.rawText"
          :placeholder="editorPlaceholder"
          ref="quill"
          slot="text"/>

        <post-media-preview
          :show="!!(state.mediaPreviewEnabled && state.media && state.media.web_url)"
          :url="state.media && state.media.web_url"
          :type="mediaType"
          :position="state.mediaPosition"
          :can-change-position="!isSent"
          @change-position="toggleMediaPosition"
          @remove="removeMedia"
          @load="enablePreviewByLoad"
          slot="media"/>
      </post-media-position-switcher>

      <el-collapse-transition>
        <post-media-menu
          :mediaMenuOpened.sync="mediaMenuOpened"
          :urlInputOpened.sync="urlInputOpened"
          v-bind="{
            uploadButtonLoading,
            urlButtonLoading,
          }"
          :url-input-value.sync="urlInputValue"
          v-on="{
            fileSelected: handleFileSelect,
            handleUrl,
          }"/>
      </el-collapse-transition>

      <div class="post__header-row">
        <h5 class="post-editor-header">{{$t('post.headers.keyboard')}}</h5>
        <button
          class="button button_theme_hover-danger button_size_xs"
          :title="$t('post.clearKeyboard')"
          @click="clearKeyboard">
          <el-icon name="delete"/>
        </button>
      </div>

      <quiz-keyboard
        v-model="state.buttons"
        :answerCharLimit="answerCharLimit"
        :channelLanguage="selectedChannel && selectedChannel.settings && selectedChannel.settings.lang"
        :answerStatsEnabled="store.state.answerStats"/>
    </div>

    <div class="post__content-settings">
      <h5 class="post-editor-header">{{$t('post.headers.settings')}}</h5>

      <checkbox-form-item
        v-model="state.answerStats"
        :label="$t('post.settingsLabels.answerStats')"/>
      <checkbox-form-item
        v-model="state.limitAnswer"
        :label="$t('post.settingsLabels.limitAnswer')"/>
      <checkbox-form-item
        v-if="!isSent"
        v-model="state.notifications"
        :label="$t('post.settingsLabels.notifications')"/>

      <span v-if="!isSent" class="post__item" :class="{'_effect_shake': datetimePickerShake}">
        {{$t('post.settingsLabels.publish')}}
        <post-datetime-picker
          :selected-channel="selectedChannel"
          :is-scheduled="state.isScheduled"
          :scheduled-date="state.scheduledDate"
          @clear="clearPublishTime"
          @save="savePublishTime"/>
      </span>
      <span class="post__item" v-if="isSent">
        {{$t('post.settingsLabels.channel')}} <span class="font-semibold">{{getChannelLabel(selectedChannel)}}</span>
      </span>
      <post-channel-select
        v-else
        class="post__channel-select"
        :selected-channel-id="state.selectedChannelId"
        :channels="channels"
        @select="state.selectedChannelId = $event"/>
      <div
        class="post__control-buttons">
        <button
          v-if="isSent"
          class="button button_type_filled"
          @click="createPost"
          :class="{
            'button_loading': createButtonLoading,
            'post__button_theme-change': createButtonThemeChange,
            'button_theme_success': createButtonThemeSuccess,
            'button_theme_danger': createButtonThemeDanger,
            'button_effect_shake': createButtonEffectShake,
          }">
          {{$t('post.actions.edit')}}
        </button>
        <template v-else>
          <button
            class="button button_type_filled"
            @click="createPost"
            :class="{
              'button_loading': createButtonLoading,
              'post__button_theme-change': createButtonThemeChange,
              'button_theme_success': createButtonThemeSuccess,
              'button_theme_danger': createButtonThemeDanger,
              'button_effect_shake': createButtonEffectShake,
            }">
            {{timerSet ? $t('post.actions.updateScheduled') : $t('post.actions.sendScheduledNow')}}
          </button>

          <button
            class="button button_type_filled button_theme_danger"
            @click="deletePost"
            :class="{'button_loading': deleteButtonLoading}">
            {{$t('post.actions.deleteScheduled')}}
          </button>
        </template>

        <div class="spacer"/>
        <el-popover
          trigger="click">
          <button
            slot="reference"
            class="button button_mobile_size_lg button_icon">
            <i class="material-icons">import_export</i>
          </button>
          <div class="flex flex-col items-stretch">
            <button
              class="button button_type_filled w-full mb-1" @click="importPostShowDialog">
              {{$t('post.actions.import')}}
            </button>
            <el-popover
              trigger="click">
              <button
                slot="reference"
                class="button button_type_filled w-full">
                {{$t('post.actions.export')}}
              </button>
              <div class="flex flex-col items-stretch">
                <pre class="post__export-preview">{{JSON.stringify(this.post)}}</pre>
                <button
                  class="button button_type_filled"
                  @click="exportPostCopy">
                  {{$t('post.export.actions.copy')}}
                </button>
              </div>
            </el-popover>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
  import ElCollapseTransition from 'element-ui/lib/transitions/collapse-transition'

  import Helmet from '@/components/Helmet.vue'
  import Message from '@/components/Message.vue'

  import QuizKeyboard from '@/components/Keyboard/QuizKeyboard.vue'

  import PostMediaPositionSwitcher from '@/components/Post/PostMediaPositionSwitcher.vue'

  const PostQuill = () => import('@/components/Post/PostQuill.vue')
  import PostMediaPreview from '@/components/Post/PostMediaPreview.vue'

  import PostMediaMenu from '@/components/Post/PostMediaMenu.vue'
  import PostDatetimePicker from '@/components/Post/PostDatetimePicker.vue'
  import PostChannelSelect from '@/components/Post/PostChannelSelect.vue'

  import CheckboxFormItem from '@/components/form/CheckboxFormItem.vue'

  import {
    apiEditPost,
    apiDeletePost,
  } from '@/functions/api'

  import {
    error401,
  } from '@/mixins/handlers'

  import {
    showErrorAlert,
    nextTick,
  } from '@/mixins/utils'

  import {
    getDefaultButton,
    getChannelLabel,
  } from '@/functions/utils'

  import {
    showErrorSnackbar,
    reportErrorAndShowSuccessSnackbar,

    showSuccessSnackbar,
  } from '@/functions/snackbar'

  import mediaMixin from '@/mixins/Post/media'
  import {
    timerMixin,
    textValidationMixin,
    formPostMixin,
    commonMixin,
    editorMixin,
  } from '@/mixins/Post/common'

  import {
    enableForMixin,
  } from '@/mixins/common'

  import {useNewpostStore} from '@/store/pinia/newpost'

  const newpostStore = useNewpostStore()

  const THEME_CHANGE_DURATION = 200
  const SUCCESS_HIGHLIGHT_DURATION = 3000
  const DANGER_HIGHLIGHT_DURATION = 3000
  const SHAKE_DURATION = 500

  import store from '@/store'
  import {chain} from '@/functions/utils'
  import {
    ensureCheckAuth,
    ensureGetChannels,
    ensureGetUser,
    getPost,
  } from '@/functions/data'
  import format from 'date-fns-tz/format'

  export default {
    name: 'PostEdit',
    mixins: [
      error401,

      showErrorAlert,
      nextTick,

      mediaMixin,
      timerMixin,
      enableForMixin,
      textValidationMixin,
      formPostMixin,
      commonMixin,
      editorMixin,
    ],
    components: {
      ElCollapseTransition,

      Helmet,
      Message,

      QuizKeyboard,

      PostQuill,
      PostMediaPreview,

      CheckboxFormItem,

      PostMediaPositionSwitcher,
      PostMediaMenu,
      PostDatetimePicker,
      PostChannelSelect,
    },
    data() {
      return {
        existingPostType: 'text',

        isSent: false,

        error: false,

        //region технические переменные
        //состояния выключателей итд
        updateButtonLoading: false,
        deleteButtonLoading: false,

        previewButtonLoading: false,
        previewButtonThemeChange: false,
        previewButtonThemeSuccess: false,
        previewButtonThemeDanger: false,
        previewButtonEffectShake: false,

        createButtonLoading: false,
        createButtonThemeChange: false,
        createButtonThemeSuccess: false,
        createButtonThemeDanger: false,
        createButtonEffectShake: false,

        datetimePickerShake: false,
        //endregion
      }
    },
    methods: {
      applyError(error) {
        this.error = error
      },
      applyPost(post) {
        const raw = post.raw

        Object.assign(
          newpostStore.state,
          {
            selectedChannelId: post.cid,

            answerStats: raw.answer_stats,
            limitAnswer: raw.limit_answer,
            notifications: raw.notifications,

            mediaPreviewEnabled: !!raw.photo_url,
            media: raw.photo_url && {
              url: raw.photo_url,
              web_url: raw.photo_url,
            },
            mediaPosition: raw.type === 'text' ? 'bottom' : 'top',

            rawText: raw.text && raw.text.replace(/\n/g, '<br>') || '',

            buttons: raw.buttons,

            isScheduled: post.type === 'scheduled' && post.status !== 'sent',
            scheduledDate: new Date(post.date),
          },
        )

        this.isSent = post.status === 'sent'
        this.existingPostType = post.raw.type
      },

      clearKeyboard() {
        newpostStore.state.buttons = [[getDefaultButton()]]
      },

      async createPost() {
        if (!this.textCorrect) {
          return
        }

        this.updateTimerSet()

        let methodKey = (() => {
          if (this.isSent) {
            // edit sent post
            return 'edit'
          }
          if (this.timerSet) {
            // update scheduled time
            return 'update'
          }
          // send scheduled now
          return 'sendScheduledNow'
        })()

        let publish_date = 'now'
        let publish_time = 'now'
        if (this.scheduleTime) {
          publish_date = this.scheduleTime
            |> this.prepareDateForFormat
            |> (_ => format(_, 'dd.MM.yyyy', {timeZone: this.selectedChannelTimezone}))
          publish_time = this.scheduleTime
            |> this.prepareDateForFormat
            |> (_ => format(_, 'HH:mm', {timeZone: this.selectedChannelTimezone}))
        }

        const confirmParams = {
          channel_name: getChannelLabel(newpostStore.selectedChannel),
          publish_date,
          publish_time,
        }

        this.$confirm(
          this.$t(`post.confirm.content.${methodKey}`, confirmParams),
          this.$t(`post.confirm.title`),
          {
            confirmButtonText: this.$t('common.yes'),
            cancelButtonText: this.$t('common.cancel'),
          },
        ).then(async () => {
          // console.log(JSON.parse(JSON.stringify(this.post)))

          this.createButtonLoading = true
          // edit sent post || update scheduled time || send scheduled now
          const method = 'editPost'
          const result = await apiEditPost(this.id, this.post)
          this.createButtonLoading = false

          if (!result.ok) {
            this.enableFor('createButtonThemeChange', DANGER_HIGHLIGHT_DURATION + THEME_CHANGE_DURATION)
            await this.nextTick()
            this.enableFor('createButtonThemeDanger', DANGER_HIGHLIGHT_DURATION)
            this.enableFor('createButtonEffectShake', SHAKE_DURATION)

            if (result.error.code === 401) {
              return this.error401()
            }
            if (result.error.code === 503) {
              //todo
              return
            }
            if (result.error.code === 400 && result.error.name === 'SCHEDULE_TIME_LIMIT') {
              this.enableFor('datetimePickerShake', SHAKE_DURATION)
              return showErrorSnackbar(
                this.$t('post.errors.scheduleTimeLimit'),
                () => this.createPost(),
                () => reportErrorAndShowSuccessSnackbar(
                  method,
                  result.error,
                  {post: this.post, id: this.id},
                ),
              )
            }
            return showErrorSnackbar(
              this.$t('post.errors.edit'),
              () => this.createPost(),
              () => reportErrorAndShowSuccessSnackbar(
                method,
                result.error,
                {post: this.post, id: this.id},
              ),
            )
          }

          this.enableFor('createButtonThemeChange', SUCCESS_HIGHLIGHT_DURATION + THEME_CHANGE_DURATION)
          await this.nextTick()
          this.enableFor('createButtonThemeSuccess', SUCCESS_HIGHLIGHT_DURATION)

          return showSuccessSnackbar(this.$t(`post.success.${methodKey}`))
        })
      },

      async deletePost() {
        this.$confirm(
          this.$t(`post.confirm.content.deleteScheduled`),
          this.$t(`post.confirm.title`),
          {
            confirmButtonText: this.$t('common.yes'),
            cancelButtonText: this.$t('common.cancel'),
          },
        ).then(async () => {
          // console.log(JSON.parse(JSON.stringify(this.post)))

          this.deleteButtonLoading = true
          let result = await apiDeletePost(this.id)
          this.deleteButtonLoading = false

          if (!result.ok) {
            if (result.error.code === 401) {
              return this.error401()
            }
            if (result.error.code === 503) {
              //todo
              return
            }
            return showErrorSnackbar(
              this.$t('post.errors.delete'),
              () => this.deletePost(),
              () => reportErrorAndShowSuccessSnackbar(
                'deletePost',
                result.error,
                {post: this.post, id: this.id},
              ),
            )
          }

          showSuccessSnackbar(this.$t(`post.success.deleteScheduled`))

          this.$nextTick(() => this.$router.push('/dashboard'))
        })
      },

      getChannelLabel,
    },
    computed: {
      id() {
        return this.$route.params.id
      },

      typesMatch() {
        return !this.isSent || this.existingPostType === this.postType
      },
      textErrorTooltipText() {
        if (this.captionTooLong) {
          return this.$t('post.textErrorTooltip.captionTooLong')
        }
        if (this.textTooLong) {
          return this.$t('post.textErrorTooltip.textTooLong')
        }
        if (this.textTooShort) {
          return this.$t('post.textErrorTooltip.textTooShort')
        }
        if (!this.typesMatch) {
          return this.$t('post.textErrorTooltip.typeMismatch', {
            existingType: this.existingPostType,
            newType: this.postType,
          })
        }
      },
      textCorrect() {
        return !this.textTooShort && !this.textTooLong && !this.captionTooLong && this.typesMatch
      },
    },
    beforeRouteEnter(to, from, next) {
      return chain(
        {to, from, store},
        next,
        [
          ensureCheckAuth,
          ensureGetUser,
          ensureGetChannels,
          ({to}) => getPost(to.params.id),
        ],
      )
    },
  }
</script>

<style scoped lang="stylus">
  @import "~@/components/Post/style.styl"
</style>
