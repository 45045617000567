<template>
  <el-popover
    trigger="click"
    placement="bottom"
    ref="timerPopover">
    <button class="button post__timer-button" slot="reference">
      <span v-if="isScheduled">
        {{timerText}}
      </span>
      <span v-else>{{$t('post.datetimePicker.now')}}</span>
      <i class="el-icon-arrow-down el-icon--right post__timer-icon"/>
    </button>

    <div class="datetime-picker__wrap">
      <datepicker
        v-model="publish_time.date"
        name="publish_time"
        :format="dateFormatter"
        :language="datepickerLanguage"
        monday-first
        maximum-view="day"
        :disabled-dates="{customPredictor: disabledDatesPredictor}"
        class="datepicker_position_bottom"
        input-class="datepicker-input el-input__inner"/>
      <span class="preposition">{{$t('post.datetimePicker.preposition')}}</span>
      <el-select
        v-model="publish_time.hour"
        placeholder="00">
        <el-option
          v-for="item in datepicker.hours"
          :key="item"
          :label="item"
          :value="item"/>
      </el-select>
      :
      <el-select
        v-model="publish_time.minute"
        placeholder="00">
        <el-option
          v-for="item in datepicker.minutes"
          :key="item"
          :label="item"
          :value="item"/>
      </el-select>

      <div class="timer-footer">
        <span class="timezone">
          {{timezoneText}}
        </span>
        <div class="spacer"/>
        <button
          class="button mr-1"
          v-if="isScheduled"
          @click="clearPublishTime">
          {{$t('post.datetimePicker.clear')}}
        </button>
        <button
          class="button button_type_filled"
          @click="savePublishTime">
          {{$t('post.datetimePicker.save')}}
        </button>
      </div>
    </div>
  </el-popover>
</template>

<script>
  import addHours from 'date-fns/addHours'
  import startOfDay from 'date-fns/startOfDay'
  import format from 'date-fns-tz/format'
  import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
  import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'

  import Datepicker from '@/components/Datepicker.vue'

  import {en, ru} from 'vuejs-datepicker/dist/locale/'

  import dateFnsLocaleMixin from '@/mixins/dateFnsLocale'

  const addZero = (v) => {
    const s = v.toString()
    if (s.length === 1) {
      return '0' + s
    }
    return s
  }

  export default {
    name: 'PostDatetimePicker',
    mixins: [
      dateFnsLocaleMixin,
    ],
    components: {
      Datepicker,
    },
    props: {
      selectedChannel: Object,

      isScheduled: Boolean,
      scheduledDate: [Date, Boolean],

    },
    data() {
      return {
        datepicker: {
          hours: Array.from(Array(24), (_, x) => x),
          minutes: Array.from(Array(60), (_, x) => x < 10 ? '0' + x : x),
        },

        publish_time: {
          date: startOfDay(this.getZonedDate(new Date())),
          hour: addHours(new Date(), 1).getHours(),
          minute: addZero(new Date().getMinutes()),
        },
      }
    },
    methods: {
      savePublishTime() {
        let date = this.publish_time.date
        date.setHours(this.publish_time.hour)
        date.setMinutes(this.publish_time.minute)
        date.setSeconds(0)
        date = this.getZonedDate(date)
        this.$emit(
          'save',
          date,
        )
        this.$refs.timerPopover.doClose()
      },
      clearPublishTime() {
        this.$emit('clear')
        this.$refs.timerPopover.doClose()
      },

      getZonedDate(date) {
        return zonedTimeToUtc(date, this.selectedChannelTimezone, true)
      },

      disabledDatesPredictor(date) {
        const diff = startOfDay(date).getTime() - utcToZonedTime(new Date(), this.selectedChannelTimezone, true).getTime()
        return diff < -86400000 || diff > 365 * 24 * 60 * 60 * 1000
      },
      dateFormatter(date) {
        return format(date, this.$t('common.dateFormatLong'), {locale: this.dateFnsLocale})
      },
    },
    computed: {
      datepickerLanguage() {
        if (this.$i18n.locale === 'ru') {
          return ru
        }
        return en
      },

      timezoneText() {
        const tzId = this.selectedChannelTimezone
        if (!tzId) {
          return ''
        }
        const timezoneText = `${tzId.split('/')[0]} / ${tzId.split('/')[1].replace(/_/g, ' ')}`
        const timezoneOffsetText = format(new Date(), 'x', {timeZone: tzId})
        return `${timezoneText} (UTC${timezoneOffsetText})`
      },

      timerText() {
        return format(
          utcToZonedTime(this.scheduledDate, this.selectedChannelTimezone, true),
          this.$t('post.datetimePicker.dateFormat'),
          {timeZone: this.selectedChannelTimezone},
        )
      },

      selectedChannelTimezone() {
        return this?.selectedChannel?.settings?.timezone_id || 'Etc/GMT'
      },
    },
  }
</script>

<style scoped lang="stylus">
  .post__timer-button
    @apply px-3 -my-2 py-2
</style>

<style lang="stylus">
  .datetime-picker__wrap
    padding 5px

    .preposition
      padding 0 13px 0 10px

    .vdp-datepicker
      display inline

    .vdp-datepicker > div:first-child
      display inline

    .datepicker-input
      width: 145px

    .el-select
      display inline

    .el-input
      width 65px
      display inline-block

    .timer-footer
      @apply flex flex-row items-center mt-2

    .timezone
      padding-right 10px
      line-height 1
      font-size 12px
      font-weight 500
      color #9d9d9d
</style>
