import { useNewpostStore } from '@/store/pinia/newpost'

import {
  apiUploadMedia,
} from '@/functions/api'

import {
  showErrorSnackbar,
  reportErrorAndShowSuccessSnackbar,
} from '@/functions/snackbar'

import { getMediaType } from '@/functions/media'

const newpostStore = useNewpostStore()

export const mediaType = {
  computed: {
    mediaType() {
      if (!newpostStore.state.media) {
        return false
      }
      const url = newpostStore.state.media.web_url
      if (typeof url !== 'string') {
        return false
      }
      return getMediaType(url)
    },
  },
}

const isCorrectFile = (item) => item.type.indexOf('image') !== -1

export default {
  mixins: [
    mediaType,
  ],
  data: () => ({
    mediaMenuOpened: false,
    urlInputOpened: false,

    urlInputValue: '',

    uploadButtonLoading: false,
    urlButtonLoading: false,
  }),
  methods: {
    removeMedia() {
      newpostStore.state.mediaPreviewEnabled = false
      setTimeout(() => {
        newpostStore.state.media = false
      }, 200)
    },

    setMedia(value) {
      newpostStore.state.media = value

      this.previewTimeout = setTimeout(() => {
        newpostStore.state.mediaPreviewEnabled = true
      }, 2000)
    },
    enablePreviewByLoad() {
      newpostStore.state.mediaPreviewEnabled = true
      clearTimeout(this.previewTimeout)
    },

    toggleMediaPosition() {
      if (newpostStore.state.mediaPosition === 'top') {
        newpostStore.state.mediaPosition = 'bottom'
      } else {
        newpostStore.state.mediaPosition = 'top'
      }
    },

    async uploadUrl(url) {
      this.urlButtonLoading = true

      const result = await apiUploadMedia(url, 'url')

      this.urlButtonLoading = false

      if (!result.ok) {
        return showErrorSnackbar(
          'Unable to upload photo',
          () => this.uploadUrl(url),
          () => reportErrorAndShowSuccessSnackbar(
            'uploadMedia',
            result.error,
            {url},
          ),
        )
      }

      this.urlInputOpened = false
      this.urlInputValue = ''
      return this.setMedia(result.result)
    },

    async uploadFile(file) {
      this.uploadButtonLoading = true
      const result = await apiUploadMedia(file, 'file')
      this.uploadButtonLoading = false


      if (!result.ok) {
        return showErrorSnackbar(
          'Unable to upload photo',
          () => this.uploadFile(file),
          () => reportErrorAndShowSuccessSnackbar(
            'uploadMedia',
            result.error,
            'file',
          ),
        )
      }

      return this.setMedia(result.result)
    },

    handleFileSelect(e) {
      if (e.target.files.length > 0) {
        const input = e.target
        const file = input.files[0]

        // clear input
        input.value = ''
        if (!/safari/i.test(navigator.userAgent)) {
          input.type = ''
          input.type = 'file'
        }

        return this.uploadFile(file)
      }
    },
    handleUrl() {
      return this.uploadUrl(this.urlInputValue)
    },

    handlePaste(e) {
      // if (!this.mediaMenuOpened) {
      //   return
      // }
      if (this.mediaChosen) {
        return
      }
      if (e.clipboardData === false) {
        return
      }
      let items = e.clipboardData.items

      if (items === undefined) {
        return
      }

      const item = Array.from(items).find(isCorrectFile)

      if (item) {
        return this.uploadFile(item.getAsFile())
      }
    },

    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      const file = Array.from(e.dataTransfer.files).find(isCorrectFile)

      if (file) {
        return this.uploadFile(file)
      }
    },
  },
  computed: {
    mediaChosen() {
      return !!newpostStore.state.media
    },
  },
  mounted() {
    window.addEventListener('paste', this.handlePaste)
    window.addEventListener('dragover', this.handleDragover)
    window.addEventListener('drop', this.handleDrop)
  },
  beforeDestroy() {
    window.removeEventListener('paste', this.handlePaste)
    window.removeEventListener('dragover', this.handleDragover)
    window.removeEventListener('drop', this.handleDrop)
  },
}
