<template>
  <div
    class="media-position-switcher"
    :class="position">
    <slot name="text"/>
    <slot name="media"/>
  </div>
</template>

<script>
  export default {
    name: 'PostMediaPositionSwitcher',
    props: {
      position: String,
    },
  }
</script>

<style scoped lang="stylus">
  .media-position-switcher
    display flex

    @apply bg-white border border-solid border-gray-200 rounded
    @apply w-full
    max-width 500px

    &.bottom
      flex-direction column

    &.top
      flex-direction column-reverse
</style>