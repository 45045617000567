<template>
  <div class="insert-row-button">
    <button class="insert-row-button__head" @click="$emit('click')" :title="$t('keyboard.insertRow')">
      <svg viewBox="0 0 30 42" class="insert-row-button__raindrop">
        <path
              d="M15 3
           Q16.5 6.8 25 18
           A12.8 12.8 0 1 1 5 18
           Q13.5 6.8 15 3z" />
      </svg>
      <AddIcon class="insert-row-button__icon"/>
    </button>
    <div class="insert-row-button__body"/>
  </div>
</template>

<script>
  import AddIcon from '@/assets/icons/add-24px.svg'

  export default {
    name: 'KeyboardInsertRowButton',
    components: {
      AddIcon,
    }
  }
</script>

<style scoped lang="stylus">
  .insert-row-button
    position relative
    width 100%
    height .25rem
    display flex
    flex-direction row
    align-items center
    transition .1s

  .insert-row-button__head
    position absolute
    z-index 10
    right 100%
    transform rotate(90deg)
    cursor pointer

    &:hover, &.focus-visible:focus
      ~ .insert-row-button__body
        opacity 1

      .insert-row-button__raindrop
        opacity 1

      .insert-row-button__icon
        opacity 1

  .insert-row-button__raindrop
    height 21px
    width 15px
    fill #4299e1
    opacity 0
    transition .1s

  .insert-row-button__icon
    position absolute
    bottom 1px
    width 15px
    height 15px
    fill white
    opacity 0
    transition .1s

  .insert-row-button__body
    flex-grow 1
    height 1px
    margin auto 0
    transition .1s
    background-color #4299e1
    opacity 0

</style>