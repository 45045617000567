<template>
  <el-collapse-transition>
    <div
      class="preview"
      v-show="show"
      :class="[position]">
      <div
        class="preview__content-wrapper-wrapper">
        <div class="preview__content-wrapper">
          <div class="preview__content">
            <img
              @load="$emit('load')"
              v-if="['image', 'animation'].includes(type)"
              :src="webUrl"
              class="image-preview"
              alt="Image preview"/>
            <video
              @load="$emit('load')"
              controls
              v-else-if="type === 'video'"
              :src="webUrl"
              class="image-preview"
              alt="Video preview"/>
            <div class="preview__control-buttons">
              <button
                v-if="canChangePosition"
                class="preview__control-buttons-button"
                :title="$t(`post.mediaPreview.move.${position === 'bottom' ? 'up' : 'down'}`)"
                @click="$emit('change-position')">
                <el-icon :name="position === 'bottom' ? 'caret-top' : 'caret-bottom'"/>
              </button>
              <button
                class="preview__control-buttons-button"
                :title="$t('post.mediaPreview.removeMedia')"
                @click="$emit('remove')">
                <el-icon name="delete"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </el-collapse-transition>
</template>

<script>
  import ElCollapseTransition from 'element-ui/lib/transitions/collapse-transition'

  import {
    getWebUrl,
  } from '@/functions/media'

  export default {
    name: 'PostMediaPreview',
    components: {
      ElCollapseTransition,
    },
    props: {
      show: Boolean,
      url: [String, Boolean],
      type: [String, Boolean],
      position: [String, Boolean],

      canChangePosition: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      webUrl() {
        return this.url && getWebUrl(this.url)
      },
    },
  }
</script>

<style scoped lang="stylus">
  .preview
    align-self flex-start
    line-height 1
    max-width 600px

    &.top
      .preview__content
        @apply rounded-t

    &.bottom
      @apply p-3 pt-0
      .preview__content-wrapper
        @apply pl-3

      .preview__content
        @apply rounded

      .preview__content-wrapper-wrapper
        position relative
      .preview__content-wrapper-wrapper:before
        content ''
        position absolute
        width 3px
        top 0
        bottom 0
        background-color #409EFF
        border-radius 1.5px
        align-self stretch

  .preview__content
    @apply overflow-hidden relative

  .preview__control-buttons
    position absolute
    right 0
    bottom 0
    z-index 10
    border-radius 4px 0 0 0
    font-size .7rem
    font-weight 700
    overflow hidden

  .preview__control-buttons-button
    background black
    opacity 0.5
    color white
    @apply p-2

    &:hover
      opacity 0.9

  .image-preview
    @apply w-full
</style>