<template>
  <VueDatepicker
    class="datepicker"
    monday-first
    maximum-view="day"
    v-bind="{...$props, ...$attrs}"
    v-on="$listeners"/>
</template>

<script>
  import VueDatepicker from 'vuejs-datepicker'

  export default {
    name: 'Datepicker',
    components: {
      VueDatepicker,
    },
    props: ['value'],
  }
</script>

<style lang="stylus">
  .datepicker, .datepicker>div:first-child
    display inline-block !important


  .datepicker_position_bottom
    .vdp-datepicker__calendar
      top calc(100% + 5px)
      bottom unset

  .vdp-datepicker__calendar
    padding 10px
    bottom calc(100% + 5px)
    border 1px solid #ebeef5 !important
    border-radius 5px
    box-shadow 0 2px 12px 0 rgba(0, 0, 0, .1)

    .prev, .next
      opacity 0.5

    .cell
      @apply rounded
      border none !important
      font-size 12px
      line-height 42px !important

      &.day.selected
        @apply text-white font-medium bg-blue-500 border-blue-500

        &:hover
          @apply bg-blue-600

      &.day:not(.blank):not(.disabled):not(.selected):hover
        @apply bg-blue-100 border-blue-100 text-blue-500
</style>