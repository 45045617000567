import { createStore } from 'pinia'

import store from '@/store'

import * as utils from '@/functions/utils'

export const useNewpostStore = createStore({
  id: 'newpost',
  state: () => ({
    selectedChannelId: -1,

    answerStats: true,
    limitAnswer: true,
    notifications: true,

    isScheduled: false,
    scheduledDate: false,

    buttons: [[utils.getDefaultButton()]],

    media: false,
    mediaPosition: 'bottom',
    mediaPreviewEnabled: false,

    rawText: '',
  }),
  getters: {
    selectedChannel: (state) => store.state.auth.userChannels.find((channel) => channel.cid === state.selectedChannelId),
  },
})
