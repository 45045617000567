<template>
  <div class="checkbox-item">
    <input
      class="checkbox"
      type="checkbox"
      :checked="value"
      @change="$emit('input', $event.target.checked)"/>
    <div class="checkbox-input">
      <span class="checkbox-input-icon"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CheckboxInput',
    props: {
      value: Boolean,
    }
  }
</script>

<style scoped lang="stylus">
  .checkbox-item
    display inline-block
    vertical-align top
    margin-bottom 0
    font-size 14px
    line-height 20px
    font-weight normal

  .checkbox-item input.checkbox
    width 0
    height 0

  .checkbox-item .checkbox-input
    display inline-block
    vertical-align top
    position relative
    -webkit-user-select none
    -moz-user-select none
    -ms-user-select none
    -o-user-select none
    user-select none
    transition opacity 0.12s linear

  .checkbox-item .checkbox-input-icon
    display inline-block
    content ''
    width 20px
    height 20px
    border 2px solid #b3b3b3
    background #fff
    border-radius 10px
    vertical-align top
    padding 0
    cursor pointer
    position relative

  .checkbox-item .checkbox-input-icon:before
    display inline-block
    content ''
    position absolute
    width 20px
    height 20px
    border 2px solid #54a9eb
    border-radius 10px
    top -2px
    left -2px
    transition opacity 0.12s linear
    opacity 0

  .checkbox-item input.checkbox:checked + .checkbox-input .checkbox-input-icon:before
    opacity 1
  .checkbox-item .checkbox-input-icon,
  .checkbox-item .checkbox-input-icon:before
    width 18px
    height 18px
    margin 1px
    border-radius 3px
  .checkbox-item
    .checkbox-input-icon
      &:before
        margin 0
        background #54a9eb url(/img/rc_icons.png) no-repeat -3px -91px
        background-clip content-box
  .checkbox-item input.checkbox:disabled + .checkbox-input
    cursor default
    pointer-events none
    opacity .65
</style>