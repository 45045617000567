<template>
  <div class="keyboard">
    <template v-for="i in Array.from({length: keyboard.length}, (v,j) => j)">
      <KeyboardInsertRowButton @click="addRow(i)"/>
      <keyboard-row
        :type="type"
        :button-limit="buttonLimit"
        :key="i"
        @delete="deleteRow(i)"
        @move="handleMove"
        :row-index="i"
        :row.sync="keyboard[i]"
        :answerCharLimit="answerCharLimit"
        :channelLanguage="channelLanguage"
        :answerStatsEnabled="answerStatsEnabled"/>
    </template>
    <button
      :title="$t('keyboard.addRow')"
      class="button keyboard__add-button mt-1"
      v-if="!addDisabled"
      @click="addRow()">
      <el-icon name="plus"/>
    </button>
  </div>
</template>

<script>
  import KeyboardRow from './KeyboardRow.vue'
  import KeyboardInsertRowButton from './KeyboardInsertRowButton.vue'

  import * as utils from '@/functions/utils'

  export default {
    name: 'Keyboard',
    components: {
      KeyboardRow,
      KeyboardInsertRowButton,
    },
    props: {
      keyboard: {
        type: Array,
        default: () => [],
      },
      type: String,
      rowLimit: Number,
      buttonLimit: Number,

      answerCharLimit: Number,
      channelLanguage: String,
      answerStatsEnabled: Boolean,
    },
    methods: {
      addRow(position = this.keyboard.length) {
        if (this.addDisabled) {
          return false
        }
        this.$emit('change', [
          ...this.keyboard.slice(0, position),
          [utils.getDefaultButton()],
          ...this.keyboard.slice(position),
        ])
      },
      deleteRow(position) {
        this.$emit('change', [
          ...this.keyboard.slice(0, position),
          ...this.keyboard.slice(position + 1),
        ])
      },

      handleMove({fromCoords, toCoords}) {
        if (toCoords[0] >= this.rowLimit) {
          return false
        }
        if (toCoords[1] >= this.buttonLimit) {
          return false
        }
        const movingButton = this.keyboard[fromCoords[0]][fromCoords[1]]
        let newKeyboard = []
        this.keyboard.forEach((row, rowI) => {
          let newRow = []
          row.forEach((button, buttonI) => {
            if (rowI === toCoords[0] && buttonI === toCoords[1]) {
              newRow.push(movingButton)
            }
            if (rowI === fromCoords[0] && buttonI === fromCoords[1]) {
              return
            }
            newRow.push(button)
          })
          if (rowI === toCoords[0] && row.length === toCoords[1]) {
            newRow.push(movingButton)
          }
          if (newRow.length > 0) {
            newKeyboard.push(newRow)
          }
        })
        this.$emit('change', newKeyboard)
      }
    },
    computed: {
      addDisabled() {
        return this.keyboard.length >= this.rowLimit
      },
    },
  }
</script>

<style scoped lang="stylus">
  .keyboard
    display flex
    flex-direction column
    align-items flex-start
    width 100%


  .keyboard__add-button
    @apply p-1
    max-width 400px
    @media (min-width 600px)
      @apply self-stretch
</style>
