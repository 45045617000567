<template>
  <label class="checkbox-form-item">
    <input
      class="checkbox-form-item__checkbox"
      type="checkbox"
      :checked="value"
      @change="$emit('input', $event.target.checked)"/>
    <div class="checkbox-form-item__content">
      <div class="checkbox-input">
        <span class="checkbox-input-icon"/>
      </div>
      <span class="checkbox-form-item__label">{{label}}</span>
    </div>

  </label>
</template>

<script>
  import CheckboxInput from './inputs/CheckboxInput.vue'

  export default {
    name: 'CheckboxFormItem',
    components: {
      CheckboxInput,
    },
    props: [
      'value',
      'label',
    ],
  }
</script>


<style scoped lang="stylus">
  .checkbox-form-item
    cursor pointer
    @apply -mx-2 inline-block

  .checkbox-form-item__content
    @apply inline-block p-2

  .checkbox-form-item__label
    display inline-block
    padding-left 12px

  .checkbox-form-item__checkbox
    width 0
    height 0
    position absolute
    opacity 0

  .checkbox-input
    display inline-block
    vertical-align top
    position relative
    -webkit-user-select none
    -moz-user-select none
    -ms-user-select none
    -o-user-select none
    user-select none
    transition opacity 0.12s linear

  .checkbox-input-icon
    display inline-block
    content ''
    width 20px
    height 20px
    border 2px solid #b3b3b3
    background #fff
    border-radius 10px
    vertical-align top
    padding 0
    cursor pointer
    position relative

    &:before
      display inline-block
      content ''
      position absolute
      width 20px
      height 20px
      border 2px solid #54a9eb
      border-radius 10px
      top -2px
      left -2px
      transition opacity 0.12s linear
      opacity 0

  .checkbox-form-item__checkbox:checked + .checkbox-form-item__content .checkbox-input .checkbox-input-icon:before
    opacity 1

  .checkbox-input-icon, .checkbox-input-icon:before
    width 18px
    height 18px
    margin 1px
    border-radius 3px

  .checkbox-input-icon:before
      margin 0
      background #54a9eb url(/img/rc_icons.png) no-repeat -3px -91px
      background-clip content-box
  .checkbox-form-item__checkbox:disabled + .checkbox-form-item__content .checkbox-input
    cursor default
    pointer-events none
    opacity .65

  .checkbox-form-item__checkbox:focus-visible + .checkbox-form-item__content
    outline 1px dotted -webkit-focus-ring-color
</style>