export const replaceQuillText = (text) => {
  let t = text
    .replace(new RegExp('\ufeff', 'g'), '')

    .replace(/<span class="ql-cursor"><\/span>/g, '')

    // emoji
    .replace(/<span class="ql-emojiblot" data-name=".*?"><span contenteditable="false"><span class="ap.*?">(.*?)<\/span><\/span><\/span>/g, '$1')

    .replace(/<strong.*?>/g, '<b>')
    .replace(/<\/strong>/g, '</b>')
    .replace(/<em.*?>/g, '<i>')
    .replace(/<\/em>/g, '</i>')

    .replace(/<p>\s*<br>\s*<\/p>/g, '<p></p>')
    .replace(/<br>/g, '\n')
    .replace(/<\/p>\s*<p.*?>/g, '\n')
    .replace(/<p.*?>/g, '')
    .replace(/<\/p>/g, '')

    .replace(/<a href="(.*)" target="_blank">/g, '<a href="$1">')

    .replace(/<\/code>(\n+)<code>/g, '$1')

    .replace(/&nbsp;|&#160;/g, ' ')
    .replace(/&pound;|&#163;/g, '£')
    .replace(/&euro;|&#8364;/g, '€')
    .replace(/&para;|&#182;/g, '¶')
    .replace(/&sect;|&#167;/g, '§')
    .replace(/&copy;|&#169;/g, '©')
    .replace(/&reg;|&#174;/g, '®')
    .replace(/&trade;|&#8482;/g, '™')
    .replace(/&deg;|&#176;/g, '°')
    .replace(/&plusmn;|&#177;/g, '±')
    .replace(/&frac14;|&#188;/g, '¼')
    .replace(/&frac12;|&#189;/g, '½')
    .replace(/&frac34;|&#190;/g, '¾')
    .replace(/&times;|&#215;/g, '×')
    .replace(/&divide;|&#247;/g, '÷')
    .replace(/&fnof;|&#402;/g, 'ƒ')
    .replace(/&Alpha;|&#913;/g, 'Α')
    .replace(/&Beta;|&#914;/g, 'Β')
    .replace(/&Gamma;|&#915;/g, 'Γ')
    .replace(/&Delta;|&#916;/g, 'Δ')
    .replace(/&Epsilon;|&#917;/g, 'Ε')
    .replace(/&Zeta;|&#918;/g, 'Ζ')
    .replace(/&Eta;|&#919;/g, 'Η')
    .replace(/&Theta;|&#920;/g, 'Θ')
    .replace(/&Iota;|&#921;/g, 'Ι')
    .replace(/&Kappa;|&#922;/g, 'Κ')
    .replace(/&Lambda;|&#923;/g, 'Λ')
    .replace(/&Mu;|&#924;/g, 'Μ')
    .replace(/&Nu;|&#925;/g, 'Ν')
    .replace(/&Xi;|&#926;/g, 'Ξ')
    .replace(/&Omicron;|&#927;/g, 'Ο')
    .replace(/&Pi;|&#928;/g, 'Π')
    .replace(/&Rho;|&#929;/g, 'Ρ')
    .replace(/&Sigma;|&#931;/g, 'Σ')
    .replace(/&Tau;|&#932;/g, 'Τ')
    .replace(/&Upsilon;|&#933;/g, 'Υ')
    .replace(/&Phi;|&#934;/g, 'Φ')
    .replace(/&Chi;|&#935;/g, 'Χ')
    .replace(/&Psi;|&#936;/g, 'Ψ')
    .replace(/&Omega;|&#937;/g, 'Ω')
    .replace(/&alpha;|&#945;/g, 'α')
    .replace(/&beta;|&#946;/g, 'β')
    .replace(/&gamma;|&#947;/g, 'γ')
    .replace(/&delta;|&#948;/g, 'δ')
    .replace(/&epsilon;|&#949;/g, 'ε')
    .replace(/&zeta;|&#950;/g, 'ζ')
    .replace(/&eta;|&#951;/g, 'η')
    .replace(/&theta;|&#952;/g, 'θ')
    .replace(/&iota;|&#953;/g, 'ι')
    .replace(/&kappa;|&#954;/g, 'κ')
    .replace(/&lambda;|&#955;/g, 'λ')
    .replace(/&mu;|&#956;/g, 'μ')
    .replace(/&nu;|&#957;/g, 'ν')
    .replace(/&xi;|&#958;/g, 'ξ')
    .replace(/&omicron;|&#959;/g, 'ο')
    .replace(/&pi;|&#960;/g, 'π')
    .replace(/&rho;|&#961;/g, 'ρ')
    .replace(/&sigmaf;|&#962;/g, 'ς')
    .replace(/&sigma;|&#963;/g, 'σ')
    .replace(/&tau;|&#964;/g, 'τ')
    .replace(/&upsilon;|&#965;/g, 'υ')
    .replace(/&phi;|&#966;/g, 'φ')
    .replace(/&chi;|&#967;/g, 'χ')
    .replace(/&psi;|&#968;/g, 'ψ')
    .replace(/&omega;|&#969;/g, 'ω')
    .replace(/&larr;|&#8592;/g, '←')
    .replace(/&uarr;|&#8593;/g, '↑')
    .replace(/&rarr;|&#8594;/g, '→')
    .replace(/&darr;|&#8595;/g, '↓')
    .replace(/&harr;|&#8596;/g, '↔')
    .replace(/&spades;|&#9824;/g, '♠')
    .replace(/&clubs;|&#9827;/g, '♣')
    .replace(/&hearts;|&#9829;/g, '♥')
    .replace(/&diams;|&#9830;/g, '♦')
    .replace(/&hellip;|&#8230;/g, '…')
    .replace(/&prime;|&#8242;/g, '′')
    .replace(/&Prime;|&#8243;/g, '″')
    .replace(/&ndash;|&#8211;/g, '–')
    .replace(/&mdash;|&#8212;/g, '—')
    .replace(/&lsquo;|&#8216;/g, '‘')
    .replace(/&rsquo;|&#8217;/g, '’')
    .replace(/&sbquo;|&#8218;/g, '‚')
    .replace(/&ldquo;|&#8220;/g, '“')
    .replace(/&rdquo;|&#8221;/g, '”')
    .replace(/&bdquo;|&#8222;/g, '„')
    .replace(/&laquo;|&#171;/g, '«')
    .replace(/&raquo;|&#187;/g, '»')
  //endregion

  let regExp = /<code>([\s\S]*?)<\/code>/g
  let match
  while ((match = regExp.exec(t)) !== null) {
    if (match[1].includes('\n')) {
      t = t.replace(match[0], `<pre>${match[1]}</pre>`)
    }
  }

  return t.trim()
}

export const formButtons = (buttons) => buttons
  .map(row => row.filter(button => button.text.length > 0))
  .filter(row => row.length > 0)
  .map(row => row.map((button) => {
    let type = button.type
    return {
      type,
      text: button.text,
      ...['article'].includes(type) && {
        content: button.content,
        isIndexed: button.isIndexed,
      },
      ...type === 'answer' && {
        answer: button.answer || '',
      },
      ...type === 'link' && {
        link: button.link || '',
      },
    }
  }))
