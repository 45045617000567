<template>
  <div class="keyboard-row" @dragover="handleDragover" @drop="handleDrop">

    <template v-for="i in range(0, row.length)">
      <KeyboardInsertButtonButton @click="addButton(i)"/>
      <div
        :key="`dummy-keyboard-button_${i}`"
        class="dummy-keyboard-button"
        :class="{'dummy-keyboard-button_transition': dummyKeyboardButtonTransition}"
        :style="getDummyKeyboardButtonStyle(i)">
        <div class="dummy-keyboard-button__content"/>
      </div>
      <component
        class="keyboard-row__item"
        :is="buttonType"
        :key="`keyboard-button_${i}`"
        :type.sync="row[i].type"
        :text.sync="row[i].text"
        :answer.sync="row[i].answer"
        :link.sync="row[i].link"
        :is-indexed.sync="row[i].isIndexed"
        :content.sync="row[i].content"
        :answerCharLimit="answerCharLimit"
        :channelLanguage="channelLanguage"
        :answerStatsEnabled="answerStatsEnabled"
        :row-index="rowIndex"
        :column-index="i"
        ref="buttons"
        @delete="handleDeleteButton(i)"/>
    </template>

    <div
      :key="`dummy-keyboard-button_${row.length}`"
      class="dummy-keyboard-button"
      :class="{'dummy-keyboard-button_transition': dummyKeyboardButtonTransition}"
      :style="getDummyKeyboardButtonStyle(row.length)">
      <div class="dummy-keyboard-button__content"/>
    </div>

    <button
      class="button keyboard-row__add-button keyboard-row__item ml-1"
      ref="addButton"
      @click="addButton()"
      :title="$t('keyboard.addButton')"
      v-if="!addDisabled">
      <el-icon name="plus"/>
    </button>

    <div class="keyboard-row__dropzones" v-show="dragoverActive">
      <div
        ref="dropzone"
        class="keyboard-row__dropzone"
        v-for="i in range(0, row.length+1)"
        @dragover="handleDropzoneDragover(i, $event)"
        @dragleave="handleDropzoneDragleave(i, $event)"
        :class="{'keyboard-row__dropzone_dragover': i === dragoverDropzoneIndex}">
      </div>

      <div class="keyboard-row__add-button-placeholder"/>
    </div>
  </div>
</template>

<script>
  const DesktopKeyboardButton = () => import('./KeyboardButton/DesktopKeyboardButton.vue')
  const MobileKeyboardButton = () => import('./KeyboardButton/MobileKeyboardButton.vue')

  import KeyboardInsertButtonButton from './KeyboardInsertButtonButton.vue'

  import * as utils from '@/functions/utils'

  const widthQuery = window.matchMedia('(min-width: 600px)')

  export default {
    name: 'KeyboardRow',
    components: {
      DesktopKeyboardButton,
      MobileKeyboardButton,

      KeyboardInsertButtonButton,
    },
    props: {
      row: Array,
      buttonLimit: Number,

      answerCharLimit: Number,
      channelLanguage: String,
      answerStatsEnabled: Boolean,

      rowIndex: Number,
    },
    data() {
      return {
        buttonType: '',

        dragoverActive: false,
        dragoverDropzoneIndex: -1,
        dragoverTimeout: 0,
        dragoverDropzoneIndexTimeout: 0,

        dummyKeyboardButtonWidth: 0,
        dummyKeyboardButtonTransition: true,
      }
    },
    methods: {
      handleWidthQueryUpdate(e) {
        if (e.matches) {
          this.buttonType = 'DesktopKeyboardButton'
          return
        }
        this.buttonType = 'MobileKeyboardButton'
      },

      handleDeleteButton(position) {
        return this.deleteButton(position)
      },

      addButton(position = this.row.length) {
        if (this.addDisabled) {
          return false
        }
        this.$emit('update:row', [
          ...this.row.slice(0, position),
          utils.getDefaultButton(),
          ...this.row.slice(position),
        ])
      },
      deleteButton(position) {
        const newRow = [
          ...this.row.slice(0, position),
          ...this.row.slice(position + 1),
        ]
        this.$emit('update:row', newRow)
        if (newRow.length === 0) {
          return this.$emit('delete')
        }
      },

      range(start, end) {
        return new Array(end - start).fill(1).map((_, i) => i)
      },

      handleDragover(event) {
        // console.log('dragover')
        event.stopPropagation()
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
        this.dragoverActive = true
        clearTimeout(this.dragoverTimeout)
        this.dragoverTimeout = setTimeout(() => {
          this.dragoverActive = false
          this.dragoverDropzoneIndex = -1
        }, 100)
      },
      handleDrop(event) {
        event.stopPropagation()
        event.preventDefault()
        const fromCoords = JSON.parse(event.dataTransfer.getData('application/json'))
        const toCoords = [this.rowIndex, this.dragoverDropzoneIndex]
        console.log(fromCoords, '=>', toCoords)

        this.dummyKeyboardButtonTransition = false
        this.$nextTick(() => {
          this.dragoverActive = false
          this.dragoverDropzoneIndex = -1
          this.$emit('move', {fromCoords, toCoords})
          setTimeout(() => {
            this.dummyKeyboardButtonTransition = true
          }, 100)
        })
      },

      handleDropzoneDragover(i, event) {
        clearTimeout(this.dragoverDropzoneIndexTimeout)
        this.dragoverDropzoneIndex = i
      },
      handleDropzoneDragleave(i, event) {
        this.dragoverDropzoneIndexTimeout = setTimeout(() => {
          this.dragoverDropzoneIndex = -1
        }, 10)
      },

      getDummyKeyboardButtonStyle(i) {
        return {
          width: this.dragoverDropzoneIndex === i ? this.dummyKeyboardButtonWidth + 'px' : '0',
          marginLeft: this.dragoverDropzoneIndex === i && i === this.row.length ? '0.25rem' : '0',
          marginRight: (this.dragoverDropzoneIndex === i && i !== this.row.length) ? '0.25rem' : '0',
        }
      },
    },
    computed: {
      addDisabled() {
        return this.row.length >= this.buttonLimit
      },
    },
    watch: {
      dragoverDropzoneIndex(v) {
        if (v === -1) {
          this.dummyKeyboardButtonWidth = 0
          return
        }
        this.dummyKeyboardButtonWidth = this.$refs.dropzone[v].getBoundingClientRect().width - 2.5
      },
    },
    mounted() {
      widthQuery.addListener(this.handleWidthQueryUpdate)
      this.handleWidthQueryUpdate(widthQuery)
    },
    beforeDestroy() {
      widthQuery.removeListener(this.handleWidthQueryUpdate)
    },
  }
</script>

<style scoped lang="stylus">
  .keyboard-row
    @apply -ml-1
    display flex
    flex-direction row
    justify-content flex-start
    align-items flex-start
    width 100%
    position relative

  .dummy-keyboard-button
    @apply flex-shrink-0 self-stretch overflow-hidden

  .dummy-keyboard-button_transition
    transition .2s

  .dummy-keyboard-button__content
    @apply w-full h-full
    @apply border border-dashed border-gray-300 rounded bg-white

  .keyboard-row__dropzones
    position absolute
    z-index 10
    @apply top-0 bottom-0 left-0 right-0
    @apply flex flex-row justify-start items-stretch

  .keyboard-row__dropzone
    @apply flex-grow
    max-width 400px

    &:not(:first-child)
      @apply pl-1

  .keyboard-row__dropzone-content
    @apply h-full w-full

  //@apply border-2 border-dashed border-blue-500

  .keyboard-row__dropzone_dragover .keyboard-row__dropzone-content
    @apply border-green-500

  .keyboard-row__add-button-placeholder
    @apply ml-1
    width 21px

  .keyboard-row__add-button
    @apply self-stretch p-1
</style>
