<template>
  <el-select
    class="channel-select"
    :value="selectedChannelId"
    @input="$emit('select', $event)">
    <el-option
      v-for="userChannel in channels"
      :key="userChannel.cid"
      :label="getChannelLabel(userChannel)"
      :value="userChannel.cid"/>
  </el-select>
</template>

<script>
  import {
    getChannelLabel,
  } from '@/functions/utils'

  export default {
    name: 'PostChannelSelect',
    props: {
      selectedChannelId: Number,
      channels: Array,
    },
    methods: {
      getChannelLabel,
    },
  }
</script>

<style scoped lang="stylus">

</style>