import {snackbar} from '@/plugins/snackbar'

import i18n from '@/i18n'

import {
  apiReportError,
} from '@/functions/api'

export class Snackbar {
  constructor({ type, message, timeToClose, closeOnAction, expanded, actions }) {
    this.params = { type, message, timeToClose, closeOnAction, expanded, actions }
  }
  show() {
    return snackbar.show(this.params)
  }
}
export class DefaultDurationSnackbar extends Snackbar {
  constructor({ type, message, closeOnAction, expanded, actions }) {
    super({
      type,
      message,
      timeToClose: 10000,
      closeOnAction,
      expanded,
      actions,
    })
  }
}
export class EternalSnackbar extends Snackbar {
  constructor({ type, message, closeOnAction, expanded, actions }) {
    super({
      type,
      message,
      timeToClose: Infinity,
      closeOnAction,
      expanded,
      actions,
    })
  }
}

export class ErrorSnackbar extends DefaultDurationSnackbar {
  constructor({ message, closeOnAction, expanded, actions }) {
    super({
      type: 'error',
      message,
      closeOnAction,
      expanded,
      actions,
    })
  }
}
export class ErrorHandlerSnackbar extends ErrorSnackbar {
  constructor(message, retryAction, reportAction) {
    let actions = []
    if (retryAction) {
      actions.push(formAction('Retry', retryAction))
    }
    if (reportAction) {
      actions.push(formAction('Report an error', reportAction))
    }
    super({
      message,
      closeOnAction: true,
      expanded: actions.length > 0,
      actions,
    })
  }
}
export class SuccessSnackbar extends DefaultDurationSnackbar {
  constructor({ message, closeOnAction, expanded, actions }) {
    super({
      type: 'success',
      message,
      closeOnAction,
      expanded,
      actions,
    })
  }
}

export class CommonSuccessSnackbar extends SuccessSnackbar {
  constructor(message) {
    super({message})
  }
}

export const formAction = (description, action) => ({
  description,
  action,
})

export const showErrorSnackbar = (message, retryAction, reportAction) => {
  return new ErrorHandlerSnackbar(message, retryAction, reportAction).show()
}

export const showSuccessSnackbar = (message) => {
  return new CommonSuccessSnackbar(message).show()
}

export const reportErrorAndShowSuccessSnackbar = async (method, error, params) => {
  const result = await apiReportError({
    method,
    timestamp: Date.now(),
    ...error && {error},
    ...params && {params},
  })
  if (result.ok) {
    return new CommonSuccessSnackbar(i18n.t('common.reportSent')).show()
  }
}
