<template>
  <div class="post__media">

    <button
      v-if="!mediaMenuOpened"
      class="button button_icon"
      :class="{'button_loading': uploadButtonLoading}"
      @click="$emit('update:mediaMenuOpened', true)">
      <AddPhotoIcon/>
    </button>

    <div v-else class="post__media-options">
      <template v-if="urlInputOpened">
        <button
          class="button"
          @click="$emit('update:urlInputOpened', false)">
          <el-icon name="close"/>
        </button>
        <el-input
          class="url-input"
          :value="urlInputValue"
          @input="$emit('update:urlInputValue', $event)"
          :placeholder="$t('post.mediaMenu.urlInputPlaceholder')">
          <el-button slot="append" class="post-media-menu__submit-url-button" icon="el-icon-check" @click="$emit('handleUrl')"/>
        </el-input>
      </template>

      <template v-else>
        <button
          class="button"
          @click="$emit('update:mediaMenuOpened', false)">
          <el-icon name="close"/>
        </button>
        <label class="post-media-menu__file-label">
          <input
            type="file"
            ref="fileInput"
            class="post-media-menu__file-input"
            @input="$emit('fileSelected', $event)"/>
          <span
            class="button -ml-2 post__file-input-label"
            :class="{'button_loading': uploadButtonLoading}">
            <el-icon name="upload"/>
            {{$i18n.t('post.mediaMenu.uploadFile')}}
          </span>
        </label>

        <button
          class="button -ml-2"
          :class="{'button_loading': urlButtonLoading}"
          @click="$emit('update:urlInputOpened', true)">
          <el-icon name="edit"/>
          {{$i18n.t('post.mediaMenu.pasteUrl')}}
        </button>

        <span class="paste-text">{{$t('post.mediaMenu.otherText')}}</span>
      </template>

    </div>
  </div>
</template>

<script>
  import AddPhotoIcon from '@/assets/icons/add_a_photo-24px.svg'

  export default {
    name: 'PostMediaMenu',
    components: {
      AddPhotoIcon,
    },
    props: {
      mediaMenuOpened: Boolean,
      uploadButtonLoading: Boolean,
      urlButtonLoading: Boolean,
      urlInputOpened: Boolean,
      urlInputValue: String,
    },
  }
</script>

<style scoped lang="stylus">
  .post__media
    .paste-text
      @apply font-bold text-gray-800

    .url-input
      max-width 350px
      box-sizing content-box
      margin-left 10px

      .post-media-menu__submit-url-button
        padding 12px

  .post__media-options
    @apply flex flex-row items-center
    @media mobile_buttons
      @apply flex-col items-start

  .post-media-menu__file-label
    position relative

  .post-media-menu__file-input
    width 0
    height 0
    position absolute
    z-index -1

  .post-media-menu__file-input:focus-visible:focus ~ .post__file-input-label
    outline 1px dotted -webkit-focus-ring-color
</style>
