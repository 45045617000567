export const enableForMixin = {
  methods: {
    enableFor(prop, duration) {
      this[prop] = true

      // console.log(`clear timeout_${prop}`)
      clearTimeout(this[`timeout_${prop}`])
      this[`timeout_${prop}`] = setTimeout(() => {
        // console.log(`timeout_${prop} run`)
        this[prop] = false
      }, duration)
    }
  }
}
