<template>
  <keyboard
    :keyboard="buttons"
    :button-limit="8"
    :row-limit="16"
    @change="$emit('change', $event)"
    :answerCharLimit="answerCharLimit"
    :channelLanguage="channelLanguage"
    :answerStatsEnabled="answerStatsEnabled"/>
</template>

<script>
  import Keyboard from './Keyboard'

  export default {
    name: 'QuizKeyboard',
    components: {
      Keyboard,
    },
    model: {
      prop: 'buttons',
      event: 'change',
    },
    props: {
      buttons: Array,
      answerCharLimit: Number,
      channelLanguage: String,
      answerStatsEnabled: Boolean,
    },
  }
</script>
